@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
/*--------------------------------------------------------------
# Default Configuration
--------------------------------------------------------------*/

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

body {
  background-color: #001220;
}

a {
  color: white !important;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Loading
--------------------------------------------------------------*/

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #5865f2;
}

.loader img {
  width: 100px;
  height: 100px;
}

.loader-no-full {
  display: flex;
  width: 50px;
  height: 50px;
  margin: 40px auto 100px auto;
}

/*--------------------------------------------------------------
# Authentification
--------------------------------------------------------------*/

.authRow {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  background-color: #2f3136;
}

.authBox {
  width: 500px;
  height: 450px;
  background-color: #202025;
  border-radius: 10px;
  margin: auto;
  display: flex;
  position: relative;
}

.authContent {
  margin: auto;
  text-align: center;
  width: 100%;
}

.authBox img {
  max-width: 180px;
  padding: 30px 50px;
}

.authBox p {
  color: gray;
  padding: 0px 50px;
  font-weight: lighter;
  font-style: italic;
}

.authBox button {
  position: relative;
  margin: 30px;
  padding: 10px 30px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bolder;
  border-radius: 5px;
  outline: none;
  border: 0;
  background-color: #5865f2;
  color: #fff;
  cursor: pointer;
}

.authContent .authBar {
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 10px;
  background-color: gray;
  overflow: hidden;
}

.authBar .authProgressBar {
  background-color: #5865f2;
  height: 10px;
  border-radius: 0 0 0px 10px;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

.navigation {
  position: absolute;
  width: 100%;
  z-index: 10000;
  color: #d4d7d9;
}

.navigationRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 50px;
  border-bottom: 1px solid #99aab5;
}
.nav-links a {
  color: #d4d7d9;
}
/* LOGO */
.logo {
  font-size: 32px;
  margin-bottom: 10px;
}

.navigation-logo-img {
  max-height: 50px;
}

.navigation-logo-img-mb {
  visibility: hidden;
  width: 0;
}
/* NAVBAR MENU */
.menu {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.menu li:hover {
  transition: 0.3s ease;
}
.menu li {
  padding: 5px 30px;
  list-style: none;
  text-transform: uppercase;
  font-weight: bold;
}
/* DROPDOWN MENU */
.services {
  position: relative;
}

.dropdown {
  background-color: #18191c;
  padding: 10px 0;
  position: absolute;
  /*WITH RESPECT TO PARENT*/
  display: none;
  border-radius: 10px;
  top: 35px;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dropdown li + li {
  margin-top: 10px;
}

.dropdown li {
  width: 35vw;
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 1vw;
  text-transform: none;
}

.dropdown hr {
  margin: 5px 10px;
  border-color: #9f9c9c;
}

.dropdown .logout {
  color: #eb5d5d;
}

.services:hover .dropdown {
  display: block;
}

.dropdownMenu {
  display: inline-flex;
  justify-content: space-between;
}

.dropdownMenu img {
  max-width: 4vw;
  margin: 0 20px;
}

.dropdownTitle p:first-child {
  font-size: 1.3rem;
  font-weight: bold;
}

.dropdownTitle p:last-child {
  font-size: 1rem;
  font-weight: normal;
}

.formNavigation a {
  font-weight: bold;
}
.navigation-boosted {
  color: #fee75c !important;
}

.navigation-account {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
}

.navigation-account-text {
  margin-right: 10px;
  color: #99aab5;
  text-align: right;
  font-size: 14px;
}

.navigation-account-name {
  font-weight: bold;
  color: #fff;
}

.navigation-account-avatar {
  max-height: 40px;
  border-radius: 50%;
  background-color: #5865f2;
}

.navigation-button {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 40px;
  height: 40px;
  background-color: #5865f2;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
  border: 0;
  transition: 0.3s;
  cursor: pointer;
}

.navigation-button i {
  margin-right: 10px;
}

.navigation-button:hover {
  background-color: #232323;
  transition: 0.3s;
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/

/* CHECKBOX HACK */
input[type="checkbox"] {
  display: none;
}
/*HAMBURGER MENU*/
.hamburger {
  display: none;
  font-size: 24px;
  -webkit-user-select: none;
          user-select: none;
  padding: 20px;
}
/* APPLYING MEDIA QUERIES */
@media (max-width: 968px) {
  .navigationRow {
    margin: 20px;
  }

  .menu {
    display: none;
    position: absolute;
    background-color: #19181c;
    border-radius: 5px;
    right: 0;
    left: 0;
    text-align: right;
    padding: 16px 0;
    margin: 0 15px;
  }

  .menu li:hover {
    display: inline-block;
    background-color: #18191c;
    transition: 0.3s ease;
  }
  .menu li + li {
    margin-top: 12px;
  }
  input[type="checkbox"]:checked ~ .menu {
    display: block;
  }
  .hamburger {
    display: block;
  }

  .navigation-logo-img {
    visibility: hidden;
    width: 0;
    margin-left: 0;
  }

  .navigation-logo-img-mb {
    visibility: visible;
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .dropdown {
    top: 30px;
    left: 0;
    right: 0;
    z-index: 5000;
  }

  .dropdown li {
    width: 100%;
    text-align: left;
    text-transform: none;
  }

  .dropdownMenu {
    flex-wrap: wrap;
  }

  .dropdownTitle p {
    padding-right: 20px;
  }

  .dropdownTitle p:first-child {
    font-size: 1rem;
  }

  .dropdownTitle p:last-child {
    font-size: 0.6rem;
  }

  .menu li img {
    display: none;
    max-width: 30px;
    margin: 0 5px;
  }

  .navigation-button {
    margin: 5px 30px;
  }
}

/*--------------------------------------------------------------
# Alert
--------------------------------------------------------------*/

.alert {
  display: block;
  position: fixed;
  bottom: 10px;
  left: 20px;
  right: 20px;
  border-radius: 10px;
  background: #ff3d4d;
  color: white;
  z-index: 1000;
}

.alertInfo {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.alertInfo img {
  max-height: 80px;
  padding: 10px;
}

.alertInfo .alertContent {
  padding: 10px 30px;
}

.alertInfo .alertTitle {
  font-size: 28px;
  font-weight: bolder;
}

.alertInfo .alertDescription {
  color: #e5e5e5;
}

@media (max-width: 720px) {
  .alert {
    display: none;
  }
}

/*--------------------------------------------------------------
# Home Header
--------------------------------------------------------------*/

.header-home {
  min-height: 95vh;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(34, 29, 105, 0.3),
      rgba(34, 29, 105, 0.2)
    ),
    url(../../static/media/hero.8f69fe50.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-slug {
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 4px 4px #000000;
}

.header-slug span {
  font-size: 36px;
  line-height: 3rem;
}

.header-subslug {
  font-size: 1.2rem;
  color: #fff;
  margin: 30px 0;
  text-shadow: 0px 4px 4px #000000;
}

.headerHomeSubtitle {
  margin-top: 5vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 4px 4px #000000;
}

.headerHomeSubtitle span {
  font-weight: bold;
  font-size: 2rem;
  color: #ffffff;
  text-shadow: 0px 4px 4px #000000;
}

.headerHomeTypes {
  display: inline-flex;
  margin: 20px 0;
}

.headerHomeTypes div {
  background: #18191c;
  border-radius: 10px;
  min-width: 20vw;
  padding: 15px 50px;
  text-align: center;
  margin: 0 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .header-home {
    min-height: 80vh;
  }

  .header-slug {
    font-size: 1.5rem;
    line-height: normal;
  }

  .header-slug span {
    font-size: 1.6rem;
    line-height: normal;
  }

  .headerHomeSubtitle {
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
  }

  .headerHomeSubtitle span {
    font-size: 1.1rem;
  }

  .headerHomeTypes {
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px auto;
  }

  .headerHomeTypes div {
    min-width: 50vw;
    margin: 10px auto;
  }
}

/*--------------------------------------------------------------
# Home Title
--------------------------------------------------------------*/

.section-title {
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0;
}

.section-title-head {
  font-size: 3rem;
  color: #5865f2;
}

@media (max-width: 1200px) {
  .section-title-head {
    font-size: 2rem;
  }
}

.returnBack {
  position: absolute;
  color: white;
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}

.header .RankingCardsTagsItem {
  font-size: 2rem;
  padding: 5px 30px;
  margin-top: 20px;
}

/*--------------------------------------------------------------
# Search
--------------------------------------------------------------*/

.searchInput {
  position: relative;
  background: #18191c;
  border: 1px solid #4f46e5;
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-size: 1.2rem;
  width: 60%;
}

.searchSuggestion {
  position: absolute;
  background-color: #99aab5;
  color: #000000;
  width: 43%;
  border-radius: 5px;
  padding: 15px;
  bottom: 15px;
  left: 28%;
}

.searchSuggestion article {
  font-weight: bold;
  padding: 20px 0;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  cursor: pointer;
}

.searchSuggestion article:last-child {
  border-bottom: 0px;
}

.searchTagSubmit {
  padding: 20px;
  color: white;
  font-size: 1.3rem;
  background-color: #10b981;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 720px) {
  .searchInput {
    width: 80%;
  }

  .searchSuggestion {
    width: 85%;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

  .searchSuggestion article {
    font-size: 1rem;
  }

  .searchSuggestion article:last-child {
    border-bottom: 0px;
  }

  .searchTagSubmit {
    margin: 20px;
  }
}

/*--------------------------------------------------------------
# Server List Normal
--------------------------------------------------------------*/

.server-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 10px;
}

.homeButton {
  text-align: center;
}

.homeNextButton {
  padding: 10px 30px;
  font-size: 1rem;
  background-color: #5865f2;
  outline: none;
  border-radius: 10px;
  border: none;
  margin-bottom: 100px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

/*--------------------------------------------------------------
# Server Card Normal
--------------------------------------------------------------*/

.RankingCardsTags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.RankingCardsTags a {
  margin: 5px;
}

.RankingCardsTagsItem {
  padding: 5px 10px;
  /* margin: 5px; */
  background-color: #4f46e5;
  border: 1px solid #4f46e5;
  transition: 0.3s;
  border-radius: 5px;
  font-size: 12px;
}

.RankingCardsTagsItem:hover {
  background-color: #5865f2;
  border: 1px solid #4f46e5;
}

.card-server {
  position: relative;
  width: 30%;
  height: 430px;
  background-color: #18191c;
  box-shadow: 0 0 15px 5px black;
  margin: 100px 15px 15px 15px;
  transition: 0.3s;
  border-radius: 10px;
  cursor: pointer;
}

.card-server:hover {
  transform: scale(1.05);
}

.card-server-logo {
  display: block;
  margin: -60px auto 0 auto;
  border-radius: 10px;
  max-width: 120px;
  height: 120px;
  text-align: center;
}

.card-server-float {
  position: absolute;
  padding: 2px;
  top: -25px;
  right: -20px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.card-server-name {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #5865f2;
  font-size: 28px;
  font-weight: bold;
}

.card-server-icons {
  width: 28px;
  height: 28px;
  margin: 5px;
}

.card-server-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.card-server-badges-content {
  padding: 10px;
  margin: 5px 10px;
  background-color: #5865f2;
  border-radius: 5px;
}

.card-server-badges-flag {
  height: 18px;
}

.card-server-separator {
  margin: 10px 20px;
}

.card-server-description {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  text-align: center;
  margin: 20px;
}

.card-server-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 18px;
  border-radius: 0 0 10px 10px;
  text-transform: uppercase;
  border: none;
  border-top: 2px solid #ffffff;
  font-weight: bold;
  background-color: #5865f2;
  position: absolute;
  bottom: 0;
  transition: 0.3s;
  cursor: pointer;
}

.card-server-button:hover {
  background-color: #7289da;
}

@media (min-width: 1620px) {
  .card-server {
    width: 20%;
  }
  .card-server-badges-content {
    font-size: 14px;
    margin: 5px;
  }
  .card-server-badges-flag {
    height: 14px;
  }
}

@media (max-width: 1080px) {
  .card-server {
    width: 45%;
  }
  .card-server-badges-content {
    font-size: 14px;
    margin: 5px;
  }
  .card-server-badges-flag {
    height: 14px;
  }
}

@media (max-width: 700px) {
  .card-server {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .card-server {
    width: 100%;
  }
}

@media (max-width: 321px) {
  .card-server {
    width: 100%;
  }
  .card-server-badges-content {
    font-size: 12px;
    margin: 2px;
  }
  .card-server-badges-flag {
    height: 12px;
  }
}

/*--------------------------------------------------------------
# Header General
--------------------------------------------------------------*/

.header {
  height: 50vh;
  background-color: #5865f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-search {
  height: 50vh;
  background-color: #5865f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerVip {
  height: 300px;
  background-color: #5865f2;
}

/*--------------------------------------------------------------
# Main Content
--------------------------------------------------------------*/

.section-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 100px;
}

.sectionButtons {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.sectionButtons .sectionButton {
  margin: 20px 50px;
  display: flex;
  background-color: #5865f2;
  border-radius: 5px;
  padding: 10px 20px;
  outline: 0;
  border: 0;
  color: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}

/*--------------------------------------------------------------
# Main Content
--------------------------------------------------------------*/

.section-server-main {
  width: 100%;
}

.serverViewBox {
  padding: 100px 40px;
}

.serverViewRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.serverViewDescription {
  width: 60%;
  margin: 10px;
}

.serverViewReviews {
  width: 40%;
  margin: 10px;
}

.serverViewTitle {
  font-size: 2rem;
  color: #4f46e5;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

.serverViewDescriptionContent {
  background-color: #18191c;
  border-radius: 10px;
  padding: 20px;
  min-height: 53vh;
  color: white;
}

.serverViewCardReview {
  background-color: #18191c;
  border-radius: 10px;
  padding: 20px;
  color: white;
  min-height: 24vh;
  margin-bottom: 10px;
}

.serverViewCardNoReview {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18191c;
  border-radius: 10px;
  padding: 20px;
  color: white;
  min-height: 53vh;
}

.serverViewCardReviewDetails {
  display: inline-flex;
  align-items: center;
}

.serverViewCardReviewDetails img {
  border-radius: 20px;
  max-width: 256px;
  margin-right: 20px;
}

.serverViewCardReviewInfo {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.serverViewCardReviewDescription {
  font-size: 1.2rem;
  padding: 20px;
  text-align: center;
  color: #99aab5;
}

.serverViewStats {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18191c;
  border-radius: 10px;
  padding: 20px;
  color: white;
  height: 53vh;
}

@media (max-width: 1200px) {
  .serverViewBox {
    padding: 50px 20px;
  }

  .serverViewRow {
    flex-wrap: wrap;
  }

  .serverViewDescription {
    width: 100%;
    margin: 0;
  }

  .serverViewReviews {
    width: 100%;
    margin: 0;
  }

  .serverViewTitle {
    font-size: 1.3rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .serverViewCardReviewDetails img {
    max-width: 64px;
  }

  .serverViewCardReviewInfo {
    font-size: 1rem;
  }

  .serverViewCardReviewDescription {
    font-size: 1.1rem;
    padding: 10px;
  }

  .serverViewStats {
    padding: 10px;
    color: white;
    height: 35vh;
  }
}

/*--------------------------------------------------------------
# RANKINGS SCREENS
--------------------------------------------------------------*/

/* Rankings First Card */
.rankingFirst {
  display: flex;
  justify-content: center;
  width: 100%;
}

.rankingFirstCard {
  position: relative;
  width: 50%;
  margin: 50px 30px;
  background-color: #fee75c;
  border-radius: 10px;
  padding: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0 0 15px 5px black;
}

.rankingFirstCard:hover {
  transform: scale(1.02);
}

.rankingFirstCardTrophyLeft {
  position: absolute;
  transform: rotate(-30deg);
  top: -30px;
  left: -30px;
  height: 80px;
}

.rankingFirstCardTrophyRight {
  position: absolute;
  transform: rotate(30deg);
  top: -30px;
  right: -30px;
  height: 80px;
}

/* Rankings Second & Third Card */
.rankingLastPodium {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.rankingSecondCard {
  position: relative;
  width: 45%;
  margin: 50px 30px;
  background-color: #99aab5;
  border-radius: 10px;
  padding: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0 0 15px 5px black;
}

.rankingThirdCard {
  position: relative;
  width: 40%;
  margin: 50px 10px;
  background-color: #ff9a62;
  border-radius: 10px;
  padding: 20px;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0 0 15px 5px black;
}

.rankingSecondCard:hover {
  transform: scale(1.02);
}

.rankingThirdCard:hover {
  transform: scale(1.02);
}

.rankingCardLogo {
  display: block;
  margin: -80px auto 0 auto;
  border-radius: 10px;
  max-width: 120px;
  height: 120px;
  text-align: center;
  border-radius: 10px;
}

.rankingCardWinner {
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  color: #18191c;
}

.rankingCardResult {
  width: 100%;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 0;
  margin-top: 10px;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .rankingFirstCard {
    width: 100%;
    margin: 80px 10px 10px 10px;
  }

  .rankingFirstCardTrophyLeft {
    display: none;
  }

  .rankingFirstCardTrophyRight {
    display: none;
  }

  .rankingSecondCard {
    width: 100%;
    margin: 80px 10px 10px 10px;
  }

  .rankingThirdCard {
    width: 100%;
    margin: 80px 10px 10px 10px;
  }
}

/* Rankings Table Card */

.rankingTable {
  width: 100%;
}

.rankingTableCard {
  display: inline-flex;
  flex-wrap: wrap;
  width: 45%;
  margin: 10px 30px;
  border-radius: 10px;
  background-color: #5865f2;
  transition: 0.3s;
  box-shadow: 0 0 15px 5px black;
}

.rankingTableCard:hover {
  transform: scale(1.01);
}

@media (max-width: 1200px) {
  .rankingTableCard {
    width: 95%;
    margin: 10px;
  }
}

.rankingTableCardContent {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rankingTableCardContent p {
  padding: 10px;
  color: #fff;
  font-weight: bolder;
}

/*--------------------------------------------------------------
# MODAL'S
--------------------------------------------------------------*/

.modal {
  position: fixed;
  background-color: #18191c99;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modalCard {
  position: relative;
  width: 50%;
  background-color: #5865f2;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 15px 5px black;
}

.modalCardClose {
  float: right;
  margin-top: -10px;
  cursor: pointer;
}

.modalCardTitle {
  text-align: center;
  color: white;
  font-size: 28px;
  font-weight: bold;
}

.modalCardDescription {
  text-align: center;
  margin-top: 20px;
  color: white;
  font-size: 18px;
}

.text-uppercase {
  text-transform: uppercase;
}

.modalCardInputs {
  margin-top: 30px;
  text-align: center;
}

.modalCard select {
  width: 90%;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #18191c;
  color: white;
  outline: 0;
  border: 0;
  font-size: 16px;
}

.modalCard input {
  width: 90%;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #18191c;
  color: white;
  outline: 0;
  border: 0;
  font-size: 16px;
  margin: 10px;
}

.modalCardButtons {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.modalCardButtons button {
  padding: 5px 20px;
  width: 200px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  border: 0;
  outline: 0;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.modalCardButtonSubmit {
  background-color: #10b981;
}
.modalCardButtonCancel {
  background-color: #f85b68;
}

@media (max-width: 720px) {
  .modalCard {
    width: 100%;
  }
}

/* Contact */

.contact {
  position: relative;
  width: 65%;
  background-color: #23272a;
  margin: 20px;
  border-radius: 20px;
  min-height: 300px;
  transition: 0.3s;
  box-shadow: 0 0 15px 5px black;
}

.contact:hover {
  transform: scale(1.05);
}

.contactSubmitButton {
  background-color: #5865f2;
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 20px 20px;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.contactButtons {
  width: 25%;
  margin: 20px;
}

.contact .contactMessage {
  font-size: 18px;
  color: white;
  padding: 20px 30px;
  font-weight: bold;
  text-align: center;
}

.text-alert {
  color: #f85b68;
  font-weight: 900;
  font-size: 22px;
}

.contactForms {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.contactForms input {
  width: 35%;
  padding: 10px 20px;
  margin: 10px 20px;
  border-radius: 5px;
  border: 0;
  outline: 0;
  background-color: #5865f2;
  color: white;
}

.contactForms input::placeholder {
  color: white;
  font-weight: bold;
}

.contactFormsMessage {
  background-color: #5865f2;
  color: white;
  width: 85%;
  min-height: 200px;
  padding: 10px 20px;
  margin: 10px 20px;
  border-radius: 5px;
  border: 0;
  outline: 0;
}

.contactButtons button {
  width: 100%;
  background-color: #5865f2;
  border-radius: 10px;
  border: 0;
  outline: 0;
  height: 150px;
  margin: 0 0 10px 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 0 15px 5px black;
}

.contactButtons button:hover {
  transform: scale(1.05);
}

.contactButtonsImg {
  max-height: 100px;
}

.contactButtonsContent {
  width: 65%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.serverCard-button-title {
  font-size: 48px !important;
  margin-bottom: -15px;
  margin-top: -15px;
}

.contactButtonsContent p {
  font-size: 18px;
}

.contactButtonsContent span {
  font-size: 22px;
}

@media (max-width: 1200px) {
  .contact {
    width: 100%;
  }

  .contactButtons {
    width: 100%;
    margin: 50px 20px;
  }

  .contactForms input {
    width: 100%;
  }

  .contactFormsMessage {
    width: 100%;
    margin-bottom: 100px;
  }
}

/*--------------------------------------------------------------
# PROGRAMS
--------------------------------------------------------------*/

.programs {
  margin: 80px;
  justify-content: right;
  color: #fff;
  width: 100%;
}

.programs-title {
  color: #5865f2;
  font-size: 28px;
  font-weight: bolder;
}

.notEligible {
  color: #f85b68;
}

.eligible {
  color: #10b981;
}

.ifEligible {
  color: #ff9a62;
}

.programs-title span {
  color: #fff;
  font-size: 28px;
  text-decoration: underline;
}

.programs-description {
  margin: 20px 0;
  text-align: justify;
  font-size: 20px;
}

.programsEligibility {
  background-color: #23272a;
  border-radius: 10px;
  margin: 30px 0;
}

.programsEligibility article {
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid #333333;
  width: 100%;
}

.programsEligibility article:last-child {
  border-bottom: 0;
}

.programsEligibility article:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.programsEligibility article:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.programsEligibility article:hover {
  background-color: #35393b;
}

.programsEligibility .programsEligibilityIcon {
  margin: 20px 30px;
  font-size: 20px;
  color: #7289da;
}

.programsEligibility .programsEligibilityDescription {
  font-size: 20px;
  margin: 20px 0;
}

.programsEligibility .programsEligibilityDescription span {
  font-weight: bolder;
}

.programsEligibility .programsEligibilityDescription p {
  color: #626366;
  font-size: 16px;
}

.programsSuccess {
  margin-top: 50px;
  text-align: center;
}

.programsButton {
  background-color: #5865f2;
  transition: 0.3s;
  padding: 20px;
  border-radius: 10px;
  border: 0;
  text-transform: uppercase;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
  margin: -20px 20px 20px 20px;
}

.programsButton:hover {
  transform: scale(1.05);
  background-color: #7289da;
}

.programsRequestSelectServer {
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
  height: 40px;
  background-color: #23272a;
  border: 0;
  outline: 0;
  color: white;
  font-weight: bolder;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .programs {
    margin: 20px;
  }

  .programsButton {
    margin: 20px;
  }
}

/*--------------------------------------------------------------
# LEGALS
--------------------------------------------------------------*/

.legals {
  margin: 80px;
  justify-content: right;
  color: #fff;
  width: 100%;
}

.legals-title {
  color: #5865f2;
  font-size: 28px;
  font-weight: bolder;
  text-transform: uppercase;
}

.legals-title span {
  color: #fff;
  font-size: 28px;
  text-decoration: underline;
}

.legals-description {
  margin: 20px 0;
  text-align: justify;
  font-size: 20px;
}

.legals-description a {
  font-weight: bold;
}

.legals-description ul {
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
# ACCOUNT SERVER INFOS
--------------------------------------------------------------*/

.accountServerTitle {
  font-weight: bolder;
  font-size: 36px;
  color: white;
  text-transform: uppercase;
  margin-left: 50px;
  margin-top: 50px;
}

.suspendedCardButton {
  background-color: #ff8a48;
}

.suspendedCardButton:hover {
  background-color: #ff5e00;
}

.warnServerSanctionContent {
  padding: 30px;
  color: white;
  text-transform: uppercase;
  margin-top: -20px;
}

.warnServerSanctionTitle {
  font-weight: bolder;
}

.warnServerSanctionSubContent {
  background-color: #5865f2;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #99aab5;
  cursor: not-allowed;
  margin-bottom: 10px;
}

.NotInstallCardButton {
  background-color: #10b981;
}

.NotInstallCardButton:hover {
  background-color: #018845;
}

/*--------------------------------------------------------------
# ACCOUNT EDIT SERVER
--------------------------------------------------------------*/

.headerServer {
  height: 90vh;
  background-color: #5865f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.headerServerRow {
  width: 100%;
  /* padding: 50px; */
}

.headerServerContent {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headerServerLogo {
  margin-right: 15vw !important;
  margin: 50px;
  border-radius: 30px;
}

.headerServerDetails {
  margin: 50px;
}

.HeaderServerBadges {
  width: 36px;
  height: 36px;
  margin: 0 5px !important;
}

.headerServerTitle {
  text-transform: uppercase;
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.headerServerReview i {
  color: #d9d9d9;
}

.headerServerReview span {
  color: #d9d9d9;
}

.headerServerDescription {
  font-size: 1.2rem;
  max-width: 60vw;
  margin: 20px 0;
  color: white;
}

.headerServerInfo {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}

.headerServerInfo div {
  background-color: #5865f2;
  padding: 10px 30px;
  border: 1px solid #7289da;
  border-radius: 10px;
  margin-right: 20px;
  text-align: center;
}

.headerServerInfo div p {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-shadow: 1px 0 #99aab5, -1px 0 #99aab5, 0 1px #99aab5, 0 -1px #99aab5,
    1px 1px #99aab5, -1px -1px #99aab5, 1px -1px #99aab5, -1px 1px #99aab5;
  color: #5865f2;
}

.headerServerInfo div span {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.headerServerButton {
  background: #ff9a62;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 10px 40px;
  cursor: pointer;
}

.headerServerButtonJoin {
  background: #10b981;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 10px 100px;
  cursor: pointer;
  transition: 0.3s;
}

.headerServerButtonJoin:hover {
  margin-left: 30px;
}

.serverEditButtonRow {
  padding: 0 50px;
}

.serverEditButtons {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1280px) {
  .headerServer {
    height: 100vh;
  }
}

@media (max-width: 920px) {
  .headerServer {
    height: 100vh;
  }

  .serverEditButtons {
    display: none;
    visibility: hidden;
  }

  .headerServerContent {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .headerServerLogo {
    margin: 50px auto !important;
    border-radius: 30px;
    max-width: 100px;
  }

  .headerServerDetails {
    margin: 20px;
  }

  .HeaderServerBadges {
    width: 20px;
    height: 20px;
  }

  .headerServerTitle {
    text-transform: uppercase;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .headerServerDescription {
    font-size: 1rem;
    max-width: 100%;
  }

  .headerServerInfo {
    flex-wrap: wrap;
  }

  .headerServerInfo div {
    margin-left: 0 !important;
    margin: 10px;
  }

  .headerServerInfo div p {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1.5px;
  }

  .headerServerInfo div span {
    font-size: 1.5rem;
  }

  .headerServerButton {
    font-size: 1rem;
    padding: 10px auto;
  }

  .headerServerButtonJoin {
    font-size: 1rem;
    padding: 10px auto;
  }

  .headerServerButtonJoin:hover {
    margin-left: 30px;
  }
}

.serverEditButtons a {
  margin-right: 20px;
}

.serverEditButtons a:last-child {
  margin: 0;
}

.serverEditButtons button {
  width: 100%;
  background-color: #5865f2;
  border-radius: 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  outline: 0;
  height: 150px;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 0 15px 5px black;
}

.serverEditButtons button:hover {
  transform: scale(1.05);
}

.serverEditButtonsImg {
  max-height: 100px;
}

.serverEditButtonsContent {
  width: 65%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.serverEditButtonsContent p {
  font-size: 18px;
}

.serverEditButtonsContent span {
  font-size: 22px;
}

.serverEditSettings {
  margin: 10px 50px;
}

.serverEditSettingsContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.serverEditTitle {
  font-weight: bolder;
  font-size: 36px;
  color: white;
  text-transform: uppercase;
}

.serverEditButtonOff {
  padding: 10px 30px;
  color: white;
  background-color: #f85b68;
  border: 0;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 12px;
  text-transform: uppercase;
}

.serverEditButtonSee {
  padding: 10px 30px;
  color: white;
  background-color: #10b981;
  border: 0;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 12px;
  text-transform: uppercase;
}

.serverEditButtonShutDown {
  padding: 10px 30px;
  margin: 0 10px;
  color: white;
  background-color: #f85b68;
  border: 0;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 12px;
  text-transform: uppercase;
}

.serverEditCard {
  padding: 50px;
}

.serverEditCardSettings {
  margin: auto;
  position: relative;
  width: 100%;
  background-color: #18191c;
  border-radius: 10px;
  color: white;
}

.serverEditCardSettingsGlobal {
  padding: 20px;
}

.serverEditCardSettingsGlobalContent {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.serverEditCardSettingsGlobalContent div {
  width: 48%;
  margin: 10px;
}

@media (max-width: 720px) {
  .serverEditCard {
    padding: 10px;
  }
  .serverEditSettings {
    margin: 30px 20px;
  }
  .serverEditTitle {
    font-size: 28px;
  }
  .serverEditCardSettingsGlobalContent div {
    width: 100%;
  }
}

.serverEditCardSettingsGlobal p {
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
}

.serverEditCardSettingsGlobal select {
  width: 100%;
  background-color: #23272a;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;
  outline: none;
}

.serverEditCardDescriptionLengthMaxLimit {
  color: rgb(255, 135, 88);
  font-weight: bold;
  font-size: 18px;
}

.serverEditCardDescriptionLengthMax {
  color: #f85b68;
  font-size: 18px;
  font-weight: bold;
}

.serverEditCardDescription {
  width: 100%;
}

.serverEditCardDescriptionContent {
  margin: 0 10px;
  padding: 0 20px;
}

.serverEditCardDescriptionContent div {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.serverEditCardDescription p {
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
}

.serverEditCardDescription textarea {
  background-color: #23272a;
  color: #99aab5;
  outline: none;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  width: 99%;
  margin: 10px 0 60px 0;
  resize: none;
}

.serverEditCardSettingsGlobal input {
  background-color: #23272a;
  color: #99aab5;
  outline: none;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  width: 99%;
  margin: 10px 0;
  resize: none;
}

.serverEditCardDescription i {
  font-size: 12px;
}

.ReactTags__tags {
  position: relative;
  width: 100% !important;
  margin: 0 !important;
}

.ReactTags__selected {
  width: 100% !important;
  margin: 10px 0 !important;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 5px;
  display: inline-block;
  width: 100% !important;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: none;
  outline: none;
  width: 100%;
  background-color: #23272a;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  background: #4f46e5;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #eb5d5d;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
  margin-left: 10px;
}

.serverEditCardSettingsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 18px;
  border-radius: 0 0 10px 10px;
  border: 0;
  font-weight: bold;
  background-color: #5865f2;
  position: absolute;
  bottom: 0;
  transition: 0.3s;
  cursor: pointer;
  text-transform: uppercase;
}

.serverEditCardSettingsButton:hover {
  background-color: #7289da;
}

.serverEditXpSpace {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 50px 0;
}

.serverEditXpContainer {
  border-radius: 10px;
  color: white;
  background-color: #23272a;
  padding: 20px;
  margin: 0 50px;
  width: 100%;
  max-height: 300px;
}

.serverEditXpScroll {
  overflow: auto;
  height: 250px;
  padding-right: 15px !important;
}

.serverEditXpLoading {
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.serverEditXpLoading img {
  margin: 40px;
  width: 110px;
}

.serverEditXpLoading p {
  color: #5865f2;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
}

.serverEditXpList {
  border-bottom: 1px solid #fff;
  padding: 10px 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.serverEditXpList:last-child {
  border-bottom: 0;
}

.serverEditXpList .serverEditXpListPseudo {
  color: #5865f2;
  font-weight: bolder;
}

.serverEditXpList .serverEditXpListDate {
  color: gray;
}

.serverEditXpList .serverEditXpListGain {
  color: #10b981;
  text-align: right;
}

.serverEditXpList .serverEditXpListLost {
  color: #f85b68;
  text-align: right;
}

.serverEditXpBoosted {
  border-radius: 10px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  color: white;
  background: #10b981;
  padding: 50px;
  margin: 0 50px;
  width: 100%;
  cursor: not-allowed;
}

.serverEditXpBoosted img {
  max-width: 256px;
}

.serverEditXpBoosted p {
  font-size: 28px;
  font-weight: bold;
}

.serverEditXpcanBuy {
  border-radius: 10px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  color: white;
  background: #ff9a62;
  padding: 50px;
  margin: 0 50px;
  width: 100%;
  cursor: pointer;
}

.serverEditXpcanBuy img {
  max-width: 250px;
}

.serverEditXpcanBuy p {
  font-size: 28px;
  font-weight: bold;
}

.serverEditXpCantBuy {
  border-radius: 10px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  color: white;
  background: #f85b68;
  padding: 50px;
  margin: 0 50px;
  width: 100%;
  cursor: not-allowed;
}

.serverEditXpCantBuy img {
  max-width: 250px;
}

.serverEditXPCantBuyContent div {
  font-size: 1.2rem;
}

.serverEditXPCantBuyContent span {
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.serverEditXPCantBuyContent p {
  font-size: 3rem;
}

@media (max-width: 720px) {
  .serverEditXpSpace {
    flex-wrap: wrap;
  }

  .serverEditXpContainer {
    border-radius: 10px;
    color: white;
    background-color: #23272a;
    padding: 20px;
    margin: 0 10px;
    width: 100%;
    max-height: 700px;
  }

  .serverEditXpScroll {
    overflow: auto;
    height: 700px;
    padding-right: 15px !important;
  }

  .serverEditXpCantBuy {
    margin: 10px 10px;
    padding: 20px;
    text-align: center;
    flex-wrap: wrap;
  }

  .serverEditXpList .serverEditXpListPseudo {
    font-size: 14px;
  }

  .serverEditXpList .serverEditXpListDate {
    font-size: 12px;
  }

  .serverEditXpList .serverEditXpListGain {
    font-size: 14px;
    min-width: 70px;
  }

  .serverEditXpList .serverEditXpListLost {
    font-size: 14px;
    min-width: 70px;
  }

  .serverEditXPCantBuyContent div {
    font-size: 1rem;
  }

  .serverEditXPCantBuyContent span {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .serverEditXPCantBuyContent p {
    font-size: 2rem;
  }

  .serverEditXpcanBuy {
    flex-wrap: wrap;
    padding: 20px;
    margin: 10px;
  }

  .serverEditXpcanBuy p {
    font-size: 1.5rem;
  }
}

.serverEditDevSpace {
  margin: 0 50px 100px 50px;
  background-color: #23272a;
  padding: 30px;
  border-radius: 10px;
  color: white;
}

.serverEditDevSpace .serverEditDevSpaceToken {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
}
.serverEditDevSpace div {
  background-color: #5865f2;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #99aab5;
  margin-top: 5px;
}

.serverEditDevSpaceTokenLink {
  color: black;
  cursor: pointer;
}

@media (max-width: 720px) {
  .serverEditDevSpace {
    margin: 0 10px 100px 10px;
  }
}

.serverEditDevPricing {
  margin: 0 50px 100px 50px;
  background-color: #ffc43a;
  padding: 30px;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 28px;
}

/*--------------------------------------------------------------
# MAIN
--------------------------------------------------------------*/

.mainContent {
  padding-left: 340px;
  /* width: 100%; */
}

@media (max-width: 1080px) {
  .mainContent {
    padding-left: 0;
    padding: 40px 0px;
    margin-top: 0px;
  }
}

/*--------------------------------------------------------------
# NAVBAR
--------------------------------------------------------------*/

.navbar {
  position: fixed;
  width: 340px;
  background-color: #010a12;
  border-right: 1px solid #052137;
  height: 100%;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  text-align: center;
  scrollbar-width: none;
  text-transform: uppercase;
  z-index: 100;
}

.navbarFixed {
  position: relative;
  width: 440px;
  background-color: #2f3136;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  scrollbar-width: none;
  text-transform: uppercase;
}

.navbar img {
  margin-top: 20px;
  max-width: 220px;
}

.navbarBox {
  margin: 10px;
  text-align: left;
}

.navbarBox hr {
  border: 1px solid #052137;
  margin-top: 20px;
  margin-left: 20px;
}

.navbarBox .navbarTitle {
  border-radius: 10px;
  padding: 10px 20px;
  color: #b9bbbe;
  font-weight: bolder;
  font-size: 12px;
}

.navbar .navbarLink:first-child {
  margin-top: 20px;
}

.navbar .navbarLink {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  text-transform: none;
  margin: 10px 0 10px 30px;
  list-style-type: none;
  /* line-height: 40px; */
  /* padding: 10px 20px; */
  outline: none;
  font-weight: bold;
}

.navbar .navbarLink i {
  margin-right: 10px;
  margin: 10px;
  font-size: 22px;
}

.navActive {
  background-color: #5865f2;
  border-radius: 5px;
  color: white;
  margin-left: 20px !important;
  padding-left: 10px;
}

.navbarNew {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 2;
}

.navbarNew p {
  background-color: #ff4444;
  color: white;
  font-size: 14px;
  line-height: normal;
  padding: 0 10px;
  border-radius: 5px;
}

.navLogout {
  color: #ff4444;
}

.navbarIcons {
  color: white;
  font-size: 24px;
}

.navbarMobile {
  display: none;
}

@media (max-width: 1080px) {
  .navbar {
    position: fixed;
    width: 80%;
    height: 100vh;
    top: 0;
    z-index: 100;
  }

  .navbarMobile {
    width: 100vw;
    display: inline-flex;
    justify-content: space-between;
    background-color: #010a12;
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .navbarMobile img {
    max-width: 200px;
    margin-left: 20vw;
  }

  .navbarMobile div {
    margin-top: 10px;
    margin-right: 10px;
  }
}

/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/

.headBox {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px;
  background-color: #010a12;
  color: white;
}

.headBarGuild {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 50px;
}

.headBarGuild img {
  max-width: 80px;
  margin-right: 20px;
  border-radius: 10px;
}

.headBarGuildName {
  display: inline-flex;
}

.headBarGuildName p {
  color: #4f46e5;
  font-size: 24px;
  margin-right: 10px;
}

.headBarGuildName span {
  background-color: #18191c;
  padding: 3px 5px;
  border-radius: 5px;
  line-height: normal;
  height: 20px;
}

.headBarGuildName img {
  max-width: 16px;
  margin: 3px 5px;
}

.headBarGuildRanking {
  color: #99aab5;
}

.headBarGuildRanking span {
  color: #10b981;
  margin-left: 10px;
}

.headBarGuildRanking .trendUp {
  color: #10b981;
}

.headBarGuildRanking .trendEqual {
  color: #ff9a62;
}

.headBarGuildRanking .trendDown {
  color: #eb5d5d;
}

.headBarUser {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.headBarButtons button:last-child {
  margin-right: 50px;
}

.headBarCoins {
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #5865f2;
  color: white;
  padding: 10px 30px;
  font-size: 14px;
  cursor: not-allowed;
  margin: 10px;
}

.headBarVIP {
  border: none;
  outline: none;
  border-radius: 10px;
  background: rgba(255, 180, 34, 0.5);
  color: #fee75c;
  padding: 10px 30px;
  font-size: 14px;
  cursor: pointer;
  margin: 10px;
}

.headBarProfil {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #99aab5;
}

.headBarSettings {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  color: #99aab5;
  cursor: pointer;
}

.headBarSettings img {
  max-width: 32px;
  border-radius: 50%;
}

.headerBarSettingsNav {
  position: absolute;
  top: 80px;
  background-color: #18191c;
  padding: 10px 30px;
  width: 250px;
  right: 40px;
  border-radius: 5px;
  z-index: 1;
  text-align: right;
}

.headerBarSettingsNav ul {
  list-style-type: none;
}

.headerBarSettingsNav li {
  font-size: 16px;
  margin: 5px 0;
  cursor: pointer;
}

.headerBarSettingsNav i {
  margin-right: 5px;
}

.headerBarSettingsNav li:last-child {
  color: #eb5d5d;
}

@media (max-width: 1080px) {
  .headBarGuild {
    margin-left: 20px;
  }

  .headBarUser {
    padding: 0 20px;
  }

  .headBarGuild img {
    display: none;
  }

  .headBarGuildName p {
    font-size: 16px;
  }

  .headBarGuildName span {
    display: none;
  }

  .headBarGuildRanking {
    font-size: 12px;
  }

  .headBarButtons {
    display: none;
  }
}
/*--------------------------------------------------------------
# DASHBOARD CARDS
--------------------------------------------------------------*/

.dashboardBox {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dashboardCard {
  margin: 50px;
  background-color: #010a12;
  border-radius: 10px;
  height: 150px;
  width: 40%;
  position: relative;
  overflow: hidden;
}

.dashboardCardDetails {
  padding: 20px 20px 0px 20px;
}

.dashboardCardDetails {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 80%;
}

.dashboardCardTitle {
  font-size: 2rem !important;
  color: #fee75c !important;
}

.dashboardCardDetails p:first-child {
  font-size: 1rem;
  color: #99aab5;
}

.dashboardCardDetails p:last-child {
  font-size: 0.8rem;
  color: #ff9a62;
}

.dashboardCard .dashboardCardCount {
  padding: 0px 20px;
  font-size: 64px;
  color: #36393f;
  text-transform: uppercase;
  font-weight: bold;
}

.dashboardCard .dashboardCardImg {
  width: 120px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.dashboardCard .dashboardCardImgLoading {
  padding: 0px 20px;
  max-width: 64px;
  margin-top: 30px;
}

.dashboardCardStats {
  padding: 10px;
  width: 48%;
}

.dashboardCardStats .dashboardCardStatsBox {
  background-color: #18191c;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  display: inline-flex;
  justify-content: space-between;
}

.dashboardCardStatsBox p {
  line-height: 36px;
  margin: 10px;
  font-size: 18px;
  color: white;
  font-weight: lighter;
}

.dashboardCardStatsImgLoading {
  max-width: 32px;
  margin: 10px;
}

.dashboardCardStatsBox p:last-child {
  color: #36393f;
  font-weight: bold;
}

.dashboardCardLogs {
  margin: 10px;
  background-color: #18191c;
  border-radius: 10px;
  min-height: 450px;
  width: 48%;
  position: relative;
  overflow: hidden;
}

.dashboardCardLogsTitle {
  padding: 20px 20px 0px 20px;
  font-size: 24px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.dashboardCardLogsImgLoading {
  max-width: 100px;
  margin: 150px 0px 0px 200px;
}

.dashboardCardLogs .dashboardCardLogsItem {
  padding: 5px 10px;
  width: 100%;
  border-bottom: 1px solid #36393f;
}

.dashboardCardLogs .dashboardCardLogsItem:last-child {
  border-bottom: 0px;
}

.dashboardCardLogsItem p {
  margin-left: 10px;
  color: #99aab5;
  font-size: 15px;
}

.dashboardCardLogsItem p:last-child {
  color: #5865f2;
  font-size: 12px;
}

.dashboardCardLogsItem img {
  width: 30px;
  height: 30px;
  border: 1px solid #5865f2;
  border-radius: 50%;
}

@media (max-width: 1080px) {
  .dashboardCard {
    width: 95%;
    margin: 10px 20px;
  }

  .dashboardCard img {
    width: 90px;
  }

  .dashboardCardStats {
    width: 95%;
  }

  .dashboardCardLogs {
    width: 95%;
  }
}

.sectionTitle {
  margin: 0 50px;
  color: white;
}

.dashboardActivity {
  margin: 50px;
  background-color: #010a12;
  border-radius: 10px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.activitiesItem {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.activitiesItem p {
  text-transform: none;
  margin-left: 10px;
}

.activitiesItem img {
  width: 50px;
  height: 50px;
  border: 1px solid #5865f2;
  border-radius: 50%;
}

.activitesItemContentLeft {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  text-align: left;
}

.activitesItemContentRight {
  margin: 20px;
  text-align: right !important;
}

.activitiesItemContentUsername {
  font-size: 18px;
  font-weight: normal;
  color: #5865f2;
}

.activitiesItemContentActivitieBoost {
  color: #ff9a62 !important;
}

.activitiesItemContentActivitie {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
}

.activitiesItemContentTimer {
  font-size: 14px;
  font-weight: lighter;
  color: #99aab5;
}

.activitiesItemContentEarn {
  font-size: 18px;
  font-weight: normal;
  color: #10b981;
}

@media (max-width: 1080px) {
  .sectionTitle {
    margin: 0 20px;
    color: white;
  }

  .dashboardActivity {
    margin: 20px;
  }

  .activitiesItem img {
    display: none;
  }

  .activitiesItemContentActivitie {
    font-size: 12px;
  }

  .activitesItemContentRight {
    width: 40%;
  }

  .activitiesItemContentTimer {
    font-size: 12px;
  }

  .activitiesItemContentEarn {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# DASHBOARD SETTINGS
--------------------------------------------------------------*/

.dashboardSettings {
  margin: 50px;
}

.dashboardSettingsRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardSettingsEditCard {
  position: relative;
  background-color: #010a12;
  padding: 20px;
  width: 60%;
  border-radius: 10px;
}

.dashboardSettingsEditCardInputRow {
  margin-bottom: 20px;
}

.dashboardSettingsEditCard p {
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
}

.dashboardSettingsEditCard select {
  width: 100%;
  background-color: #23272a;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px;
  font-size: 14px;
  margin-top: 10px;
  outline: none;
}

.dashboardEditButton {
  text-align: center;
}

.dashboardEditButton button {
  background-color: white;
  outline: none;
  border: none;
  padding: 10px 50px;
  margin: 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.dashboardSettingsDescription {
  position: relative;
  background-color: #010a12;
  color: white;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  margin-top: 50px;
}

.descriptionLengthMaxLimit {
  color: rgb(255, 135, 88);
  font-weight: bold;
  font-size: 18px;
}

.descriptionLengthMax {
  color: #f85b68;
  font-size: 18px;
  font-weight: bold;
}

.dashboardSettingsDescriptionRow {
  width: 100%;
}

.dashboardSettingsDescriptionContent {
  margin: 0 10px;
  padding: 0 20px;
}

.dashboardSettingsDescriptionContent div {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.dashboardSettingsDescriptionRow p {
  font-size: 18px;
  font-weight: bolder;
  text-transform: uppercase;
}

.dashboardSettingsDescriptionRow textarea {
  background-color: #23272a;
  color: #99aab5;
  outline: none;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  width: 99%;
  margin: 10px 0;
  resize: none;
}

.dashboardSettingsDescriptionRow i {
  font-size: 12px;
}

@media (max-width: 1080px) {
  .dashboardSettings {
    margin: 20px;
  }

  .dashboardSettingsRow {
    flex-wrap: wrap;
  }

  .dashboardSettingsEditCard {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# DASHBOARD STATS & HISTORY
--------------------------------------------------------------*/

.dashboardStats {
  margin: 50px;
}

.dashboardStatsHead {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardStatsHead button {
  color: white;
  padding: 10px 50px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.dashboardStatsHead .available {
  background-color: #4f46e5;
}

.dashboardStatsHead .unvailable {
  background-color: #36393f;
  cursor: not-allowed;
}

.dashboardStatsHead h2 {
  color: white;
  text-align: center;
}

.dashboardStatsHead i {
  margin: 0 5px;
}

.dashboardStatsRow {
  margin: 0 50px;
}

.dashboardStatsContent {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.dashboardStatsCard {
  width: 29%;
  background-color: #010a12;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
}

.noDataSignal {
  color: white;
  text-align: center;
  font-size: 24px;
}

.dashboardStatsItem {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardStatsItem p {
  font-size: 16px;
  color: #99aab5;
}

.dashboardStatsItem h2 {
  color: white;
}

.dashboardStatsItem h2 span{
  color: #99AAB5;
  font-size: 12px;
}

.dashboardCardItemId {
  font-size: 12px;
}

.dashboardStatsItem img {
  max-width: 85px;
  border-radius: 10px;
}

.dashboardCardSubOnly {
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(97deg, #ffb762 27.96%, #fee75c 86.01%);
}

.dashboardCardSubOnlyRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardCardSubOnlyRow img {
  margin: 50px;
  max-width: 300px;
}

.dashboardCardSubOnlyContent {
  margin: 50px;
}

.dashboardCardSubOnlyContent p:first-child {
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboardCardSubOnlyContent span {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboardCardSubOnlyContent p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
}

.dashboardCardSubOnlyButton {
  display: inline-flex;
  margin: 10px 0;
}

.dashboardCardSubOnlyButton button:first-child {
  background-color: white;
  padding: 10px 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dashboardCardSubOnlyButton button:last-child {
  padding: 10px 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.dashboardCardGraphics {
  background-color: #010a12;
  border-radius: 10px;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53vh;
}

@media (max-width: 1080px) {
  .dashboardStats {
    margin: 20px;
  }
  
  .dashboardStatsHead {
    justify-content: center;
    flex-wrap: wrap;
  }

  .dashboardStatsRow {
    margin: 0 20px;
  }
  
  .dashboardStatsContent {
    flex-wrap: wrap;
  }
  
  .dashboardStatsCard {
    width: 100%;
    margin: 10px 0;
  }

  .dashboardCardSubOnlyRow img {
    display: none;
  }

  .dashboardCardSubOnlyContent p:first-child {
    font-size: 24px;
  }
  
  .dashboardCardSubOnlyContent span {
    font-size: 36px;
  }
  
  .dashboardCardSubOnlyContent p {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0;
  }
  
  .dashboardCardSubOnlyButton {
    justify-content: center;
    flex-wrap: wrap;
  }

  .dashboardCardSubOnlyContent {
    margin: 30px;
  }
  
}

/*--------------------------------------------------------------
# Subscription
--------------------------------------------------------------*/

.subsriptionSection {
  width: 100%;
  min-height: 80vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, #3834ED 0%, #ffb758 100%);
}

.subsriptionSection .subscriptionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  text-align: center;
}

.subsriptionSection p {
  font-size: 3rem;
  color: white;
}

.subsriptionSection span {
  font-weight: bolder;
}

.subsriptionSection button {
  background-color: white;
  margin-top: 30px;
  color: black;
  font-size: 1rem;
  padding: 10px 50px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.subsriptionSection button:hover {
  background-color: #000;
  color: white;
  padding: 10px 100px;
}

.subscriptionAddons {
  margin-top: -35vh;
  padding: 0 50px;
  color: white;
}

.subscriptionAddonsBox {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.subscriptionAddonsItem {
  position: relative;
  background-color: #010A12;
  min-height: 350px;
  width: 25%;
  padding: 20px;
  margin: 30px 0;
  border-radius: 10px;
  transition: 0.5s;
}

.subscriptionAddonsItem:hover {
  transform: scale(1.05);
}

.subscriptionAddonsItem img {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 10px;
}

@media (max-width: 1080px) {

  .subsriptionSection p {
    font-size: 2rem;
  }

  .subscriptionAddons {
    padding: 0 10px;
  }

  .subscriptionAddonsItem {
    width: 100%;
  }

}

/*--------------------------------------------------------------
# Review Dashboard
--------------------------------------------------------------*/

.dashboardReviewContainer {
  padding: 20px 50px;
}

.dashboardReviewItem {
  background-color: #010A12;
  color: #99AAB5;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.dashboardReviewItemRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboardReviewUser {
  text-align: center;
  padding: 20px;
}

.dashboardReviewUser img{
  border-radius: 50%;
}

.dashboardReviewDetails {
  text-align: center;
  padding: 20px;
  min-width: 50%;
}

.dashboardReviewDetails i {
  font-size: 20px;
  margin: 20px 5px;
}

.dashboardReviewDetails p {
  color: #4f46e5;
  font-size: 1.1rem;
}

.dashboardReviewDetails p:last-child {
  color: #99AAB5;
  padding: 20px;
  font-size: 1rem;
}

.dashboardReviewActions {
  padding: 20px;
}

.dashboardReviewActions button {
  padding: 10px 50px;
  min-width: 100%;
  margin: 5px 0;
  border-radius: 5px;
  border: none;
  outline: none;
color: white;
cursor: pointer;
font-weight: bold;
}

.dashboardReviewActions p {
  text-align: center;
}


/*--------------------------------------------------------------
# ERROR 404
--------------------------------------------------------------*/

.errorContainer {
  background-color: #23272a;
  border-radius: 10px;
  width: 100%;
  margin: 100px 50px;
  text-align: center;
  padding: 30px 0;
}

.errorContainer img {
  max-width: 250px;
  margin-top: -110px;
  border-radius: 10px;
}

.errorContainer p {
  color: white;
  font-weight: bold;
  padding: 20px;
  font-size: 28px;
}

.errorContainer a {
  color: white;
  font-weight: bold;
  padding: 10px 30px;
  margin: 20px 0;
  font-size: 24px;
  background-color: #5865f2;
  border-radius: 10px;
  border: 0;
  outline: 0;
}

@media (max-width: 720px) {
  .errorContainer {
    margin: 80px 20px;
  }
}

/*--------------------------------------------------------------
# Invitation
--------------------------------------------------------------*/

.invite {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  background: url(../../static/media/app-background.3a2772e2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.inviteError {
  background: url(../../static/media/app-background-error.907ddd11.svg);
}

.inviteRow {
  width: 70vw;
  height: 450px;
  margin: auto;
  display: flex;
  position: relative;
}

.inviteContent {
  margin: auto;
  text-align: center;
  width: 100%;
}

.inviteDescription {
  margin: 30px 0;
}

.inviteContent h1 {
  color: white;
  font-size: 3rem;
  padding: 0px 50px;
  font-weight: bold;
}

.inviteContent p {
  color: white;
  padding: 10px 50px;
  font-size: 1.5rem;
}

.inviteButton button {
  position: relative;
  margin: 30px;
  padding: 10px 100px;
  font-size: 18px;
  border-radius: 5px;
  outline: none;
  border: 0;
  background-color: #5865f2;
  color: #fff;
  cursor: pointer;
}

.inviteButton button:last-child {
  background-color: white !important;
  color: #5865f2 !important;
}

.inviteCancel {
  color: #626366 !important;
  font-size: 1rem !important;
}

@media (max-width: 720px) {
  .inviteRow {
    width: 100vw;
  }

  .inviteContent {
    margin: 0;
  }

  .inviteDescription {
    margin: 10px 0;
  }

  .inviteContent h1 {
    font-size: 2rem;
    padding: 20px;
  }

  .inviteContent p {
    color: white;
    padding: 10px;
    font-size: 1rem;
  }

  .inviteButton button {
    position: relative;
    margin: 10px;
    width: 90vw;
    padding: 10px 30px;
    font-size: 18px;
  }
}

/*--------------------------------------------------------------
# VIP
--------------------------------------------------------------*/

.vipCard {
  background-color: #5865f2;
  border-radius: 20px;
  padding: 40px;
  margin: 40px;
}

.vipRow {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.vipRow img {
  max-width: 30vw;
  max-height: 40vh;
}

.vipTitle {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}

.vipTitle span {
  font-size: 4rem;
}

.vipSubTitle {
  font-size: 2rem;
  color: white;
  line-height: 48px;
}

.vipForm {
  margin: 10px 0;
}

.promotionnalCodeApplied {
  color: white;
  text-align: center;
}

.vipForm .promotionnalCodeInput {
  padding: 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px 0 0 5px;
  width: 570px;
}

.vipForm input {
  padding: 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 600px;
}

.vipForm select {
  padding: 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 630px;
  margin-bottom: 30px;
}

.vipBasket {
  width: 100%;
  margin: 20px 0;
  color: white;
  text-align: center;
}

.vipBasket span {
  background-color: #002240;
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.buttonPromotionnal {
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #ff9a62;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.vipNext {
  padding: 10px 30px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #10b981;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 30px;
  cursor: pointer;
}

.vipNextProcessing {
  padding: 10px 30px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #10b981;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.vipWaiting {
  padding: 40px;
  text-align: center;
}

.vipWaitingGrid {
  background-color: #23272a;
  border-radius: 10px;
  padding: 40px;
  margin: 40px;
}

.vipWaitingRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
}

.vipWaitingRow p {
  font-weight: bold;
  font-size: 1.2rem;
}

.vipSeparator {
  margin: 20px 0;
}

.vipTable {
  color: white;
  list-style-type: none;
}

.vipTableRow {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.vipTableRow p {
  color: #c4c4c4;
  font-size: 1.1rem;
}

.vipTableRow span {
  background-color: #99aab5;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: normal;
  margin-left: 20px;
  cursor: pointer;
}

.vipActive p {
  font-weight: bold;
  font-size: 1.1rem;
  color: white;
}

.vipActive span {
  background-color: #4f46e5;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: normal;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .vipCard {
    padding: 20px;
    margin: 10px;
    text-align: center;
  }

  .vipRow img {
    display: none;
  }

  .vipTitle {
    font-size: 1rem;
  }

  .vipTitle span {
    font-size: 1.2rem;
  }

  .vipSubTitle {
    font-size: 1rem;
  }

  .vipForm .promotionnalCodeInput {
    width: 270px;
  }

  .vipForm input {
    width: 300px;
  }

  .vipForm select {
    width: 330px;
  }

  .vipNext {
    margin-left: 0px;
    margin-top: 5px;
  }

  .vipWaiting {
    padding: 10px;
  }

  .vipWaitingGrid {
    padding: 20px;
    margin: 10px;
  }

  .vipTableRow p {
    font-size: 1rem;
  }

  .vipTableRow span {
    margin-left: 0px;
    display: none;
  }

  .vipActive p {
    font-size: 1rem;
  }

  .vipActive span {
    display: none;
  }
}

/*--------------------------------------------------------------
# PageTheme
--------------------------------------------------------------*/

.dtopTheme {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  background: url(../../static/media/app-background.3a2772e2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dtopThemeError {
  background: url(../../static/media/app-background-error.907ddd11.svg);
}

.dtopThemeRow {
  width: 70vw;
  height: 450px;
  margin: auto;
  display: flex;
  position: relative;
}

.dtopThemeContent {
  margin: auto;
  text-align: center;
  width: 100%;
}

.dtopThemeDescription {
  margin: 30px 0;
}

.dtopThemeContent h1 {
  color: white;
  font-size: 3rem;
  padding: 0px 50px;
  font-weight: bold;
}

.dtopThemeContent p {
  color: white;
  font-size: 1.5rem;
}

.dtopThemeServerSelector {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.serverSelectorCard {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 25px;
  background-color: #18191c;
  padding: 20px;
  border-radius: 10px;
  transition: 0.5s;
}

.serverSelectorCard:hover {
  background-color: #4f46e5;
  transition: 0.5s;
  cursor: pointer;
}

.serverSelectorCard img {
  width: 100px;
  border-radius: 5px;
}

.serverSelectorCard p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.serverSelectorCard span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  border-top: 1px solid #fff;
}

.serverSelectorCard .active {
  background-color: #10b981;
}

.serverSelectorCard .ready {
  background-color: #ff8a48;
}

.serverSelectorCard .suspended {
  background-color: #f85b68;
}

.serverSelectorCard .needBot {
  background-color: #4f46e5;
}

.dtopThemeButton button {
  position: relative;
  margin: 30px;
  padding: 10px 100px;
  font-size: 18px;
  border-radius: 5px;
  outline: none;
  border: 0;
  background-color: #5865f2;
  color: #fff;
  cursor: pointer;
}

.dtopThemeButton button:last-child {
  background-color: white !important;
  color: #5865f2 !important;
}

.dtopThemeCancel {
  color: #626366 !important;
  font-size: 1rem !important;
}

@media (max-width: 720px) {
  .dtopTheme {
    display: block;
  }

  .dtopThemeRow {
    width: 100vw;
  }

  .dtopThemeContent {
    margin: 0;
  }

  .dtopThemeDescription {
    margin: 10px 0;
  }

  .dtopThemeContent h1 {
    font-size: 2rem;
    padding: 20px;
  }

  .dtopThemeContent p {
    color: white;
    padding: 10px;
    font-size: 1rem;
  }

  .serverSelectorCard {
    margin: 5px;
  }

  .dtopThemeButton button {
    position: relative;
    margin: 10px;
    width: 90vw;
    padding: 10px 30px;
    font-size: 18px;
  }
}

/*--------------------------------------------------------------
# FOOTER
--------------------------------------------------------------*/
.footer {
  background-color: #5865f2;
}

.footer-content {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.footer-links {
  padding: 50px;
  max-width: auto;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  font-weight: 300;
}

.footer-links-list {
  margin-top: 30px;
  list-style-type: none;
}

.footer-links-list li {
  margin: 10px 0;
}

.footer-title {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  color: #18191c;
}

.footerTitleDashboard {
  font-size: 1rem;
  text-align: center;
  color: #5865f2;
  margin-bottom: 20px;
}

.footer-copyrigth {
  display: block;
  text-align: center;
  width: 100%;
}

.footer-copyrigth p {
  font-size: 16px;
  padding: 20px;
  font-weight: 900;
  color: #18191c;
}

.footer-copyrigth button {
  font-size: 16px;
  margin: 20px;
  font-weight: 900;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  border: 0;
  padding: 5px 10px;
  background-color: #18191c;
  color: white;
}

.footer-support {
  padding: 50px;
  max-width: auto;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  font-weight: 300;
}

.footer-support-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
  width: 20vw;
  height: 20vh;
  border-radius: 10px;
}

.footer-support-card p {
  text-align: center;
  color: #4f46e5;
  font-weight: lighter;
  font-size: 1.6rem;
  margin-top: -20px;
}

.footer-support-card span {
  font-weight: bold;
}

.footer-support-card a {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 10px 0;
  border-radius: 0 0 9px 9px;
  width: 100%;
  font-weight: bold;
  background-color: #4f46e5;
}

@media (max-width: 1200px) {
  .footer-title {
    font-size: 1.5rem;
  }

  .footer-support-card {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Waves
--------------------------------------------------------------*/

.waves {
  margin-top: -230px;
  width: 100%;
  z-index: 4;
}

@media (max-width: 1280px) {
  .waves {
    margin-top: -120px;
  }
}

@media (max-width: 720px) {
  .waves {
    margin-top: -60px;
  }
}

.wave1 use {
  animation: move-forever1 10s linear infinite;
  animation-delay: -2s;
}

.wave2 use {
  animation: move-forever2 8s linear infinite;
  animation-delay: -2s;
}

.wave3 use {
  animation: move-forever3 6s linear infinite;
  animation-delay: -2s;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

/*--------------------------------------------------------------
# SCROLLBAR
--------------------------------------------------------------*/

::-webkit-scrollbar {
  width: 10px;
  padding: 5px !important;
}

::-webkit-scrollbar-track {
  background: #18191c;
}

::-webkit-scrollbar-thumb {
  background-color: #5865f2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7289da;
}

